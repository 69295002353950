import $ from './lib/load_jquery';
import 'bootstrap';
import 'lazysizes';
import 'slick-carousel';
import 'focus-visible';
import 'magnific-popup';

$( document ).ready(function() {
    /*SlickSlider Startseite*/
    $('.stage-slider').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
    });

    // Pause/Play-Funkion für den Stage-Slider
    if ($(".stage-slider").html() != null) {
        // Play/Pause Button hinzufuegen
        $(".stage-slider").parent().css('position', 'relative').append('<div class="is_play"><a id="play" href="javascript:;" alt="Bildwechsel der Bühnengalerie starten" title="Bildwechsel abspielen"><span class="icon">►</span></a></div>');
        $(".stage-slider").parent().append('<div class="is_breake"><a id="breake" href="javascript:;" alt="Bildwechsel der Bühnengalerie anhalten" title="Bildwechsel anhalten"><span class="icon">❚❚</span></a></div>');
        // Der Zurueck-Button ist zunaechst ausgeblendet
        $(".is_back").hide();
    }
    // Beim Klick auf Stop wird animation pausiert
    $("#breake").click(function () {
        $('.stage-slider').slick('slickPause');
        $("#breake").hide();
        $("#play").show();
    })

    // Beim Klick auf Play wird animation fortgesetzt
    $("#play").click(function () {
        $('.stage-slider').slick('slickPlay');
        $("#play").hide();
        $("#breake").show();
    })


    /*GallerySlider Detailseiten*/
    $('.gallery-slider').slick({
        autoplay: false,
        dots: false,
    });


    /*Sticky Mainnavi*/
    // When the user scrolls the page, execute myFunction
    window.onscroll = function () {
        myFunction()
    };

    // Get the navbar
    var navbar = document.getElementById("mainnavi");

    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
        } else {
            navbar.classList.remove("sticky");
        }
    }


    /*mobile Navigation*/
    $('.navbar-toggle').on('click', function () {
        $('#mainnavi').toggleClass('active');
        $('#mainnavi .navi-toggle-container').toggleClass('visible');
    })

    $(".exp-header").on('click', function () {
        $(this).find(".arrow--down").toggleClass('active');
        $(this).find(".arrow--up").toggleClass('active');
        $(this).parent().next().toggleClass('active');
    });

    /*Navi 1st-lvl aktiv setzten bei Klick*/
    $("ul.nav-first-lvl > li:not(.donation) > div > a").on('click',function(e){
       e.preventDefault();
       $(this).closest('li').toggleClass('active').siblings().removeClass('active');
    });
    $(document).click(function(event) {
        var $target = $(event.target);
        if(!$target.closest('.navi-toggle-container').length ) {
            $("ul.nav-first-lvl > li").removeClass('active');
        }
    });


    /*Search-Toggle*/
    function searchToggle(obj, evt) {
        var container = $(obj).closest('.search-wrapper');
        if (!container.hasClass('active')) {
            container.addClass('active');
            evt.preventDefault();
        } else if (container.hasClass('active') && $(obj).closest('.input-holder').length == 0) {
            container.removeClass('active');
            // clear input
            container.find('.search-input').val('');
        }
    }
    $('.search-icon').on('click',function(){
        searchToggle(this, event);
    });
    $('.search-close').on('click',function() {
        searchToggle(this, event);
    });

    /**
     * Name           : MagnificPopup
     * Beschreibung   : PopUps und Lightbox für Artikelbilder, Hinweise und Formulare
     * Parameter      : Klasse des Elements
     * Rückgabe       : -
     * Bibliothek     : jQuery, Magnific Popup (magnific_min.js)
     */
    var groups = {};
    $('.mfp').each(function() {
        var id = parseInt($(this).attr('data-group'), 10);

        if(!groups[id]) {
            groups[id] = [];
        }

        groups[id].push( this );
    });

    $.each(groups, function() {

        $(this).magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: true,
            gallery: { enabled:true },
            closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg viewBox="0 0 25 25" class="icon-sm"><use xlink:href="/public/assets/global/img/icons.svg#close"></use></svg></button>'
        })

    });

});